<template>
  <div>
         <nav-bar class="detail-nav">
     <div class="back" slot="left">
          <img slot="left" >
    </div>      
    <div class="title" slot="center">
     <div slot="center">身份绑定</div>
    </div>
  </nav-bar>
   <div class="content">
       <div class="header">
           <p>绑定员工身份获得权益:</p>
           <p>1. 线上查询工资</p>
             <p>2. 线上申请借支</p>
           <!-- <img src="~assets/images/common/logo_tenger.png"> -->
       </div>
       <div class="inputBody">
           <!-- <van-cell-group class="cell"> -->
      <!-- <div class="cell">         -->
  <van-field
    v-model="name"
    rows="1"
    
    autosize
    label="姓名"
    class="filed "
    type="textarea"
    input-align="right"
    placeholder="请输入姓名"
  />
   <van-field
    v-model="idCard"
    rows="1"
    
    autosize
    label="身份证"
    class="filed "
    type="textarea"
    input-align="right"
    placeholder="请输入身份证号码"
  />
       </div>
       <div class="tip">
           <p>温馨提示:</p>
           <p>此身份证只能绑定一个手机号码,是判断员工的唯一依据,请如实填写</p>
       </div>

       <div class="bt">
           
    <van-button  class="binding" @click="commit">立即绑定</van-button>
</div>

   </div>

  </div>

  
</template>

<script>
import NavBar from 'common/navbar/NavBar'
import {idCard} from "network/identify";
import { Cell, CellGroup,Field,Button, Toast } from 'vant';
// import { Cell, CellGroup,Field,Button,DatetimePicker,Popup ,Picker, Toast} from 'vant';
export default {
    data () {
        return {
         name:'',
         idCard:''
        }
    },
   components: {
         NavBar,
         [Field.name]:Field,
         [CellGroup.name]:CellGroup,
         [Button.name]:Button
       
   },
    created () {
    
    },
   methods: {
     commit(){
         if(!this.name){
         Toast.fail('姓名不能为空');
         return
         }
         if(!this.idCard){
         Toast.fail('身份证号码不能为空');
         return
         }
       idCard(this.name,this.idCard).then(res=>{
         
           if(res=='绑定成功'){
        this.$router.push('/sucefulResult')
           }else{
          Toast.fail('绑定失败')
           }
     
     })

        //  
    
    
    
    }
       
   },
 

}
</script>

<style scoped>
.header{
    padding: 15px;
    height: 105px;
    line-height: 1.8;
    margin-bottom: 20px;
    background-color: rgb(253, 180, 10)
}
.header p{
    color: #333;
    font-weight: 400;
    font-size: 14px
}
.tip{
    width: 88%;
    margin: 0 auto;
    padding-top: 25px;
    line-height: 1.8
}
.tip p{
    color: red;
    font-size: 13px;
    font-weight: 400
}
.filed{
    display: flex;
    align-items: center;
    margin-top: 12px;
     border-radius: 5px !important
}
.binding{
      width: 90%;
    margin: 0 auto;
    border-radius: 5px;
    color: #fff;
    /* background-color:  */
     background-image: linear-gradient(to right, rgb(243, 70, 31) , rgb(243, 40, 71));
    /* background-color: #f00 */
}
.bt{
    display: flex;
    justify-content: center;
    margin-top: 40px
}
.login{
    width: 90%;
    margin: 0 auto
}
.input{
    margin-top: 13px;
   border: 1px solid #ddd;
   /* padding: 30px */
   padding-left: 12px;
   padding-top: 15px;
   padding-bottom: 15px;
   border-radius: 5px
}
input{
    background-color: transparent;
    border: none;
   margin-left:10px 

}
.inputBody{
    width: 90%;
    /* margin-top: 390px; */
    margin: 0 auto
}
/* input::input-placeholder { text-align: 'center' } */
.inputtype >>> .van-cell__value{
  display: flex
}
.logo img{
    width: 140px
}
.logo{
    
    display: flex;
    justify-content:center;
}
.item{
    display: flex;
    /* justify-content: space-between */
}
.item :first-child{
  margin-right: 16px
    /* display: flex; */
    /* justify-content: space-between */
}
.commit{
    position: fixed;
    bottom: 0;
    width: 100%;
}
.back{
 /* background-color: aquamarine; */
   align-content: center;
   height: 55px;
   line-height: 55px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}
</style>